.about .cv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about .cv li {
  min-width: 18rem;
  text-align: center;
}

.about .cv .icon {
  display: block;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
}

.about hr {
  border-color: var(--color-text-25);
  margin: 3rem auto;
}
