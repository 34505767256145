.footer {
  background: #1e213a;
  color: #585b74;
  padding: 3rem 0;
}

.footer a {
  text-decoration: none;
  color: #80829c;
  transition: color 0.2s ease;
}
.footer a:hover {
  text-decoration: underline;
  color: #999dcf;
}

.footer nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer nav ul li {
  margin: 1rem 2rem;
}
