html {
  font-size: 62.5%;
}
body {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 1.6;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
} */
h1 {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}
h3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -0.1rem;
}
h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -0.08rem;
}
h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05rem;
}
h6 {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0;
}

p:not(:last-child) {
  margin-bottom: 1.6rem;
}

.text {
  /* font-family: Calibri, 'Trebuchet MS', sans-serif; */
  font-size: 1.75rem;
  line-height: 1.75;
  text-align: left;
}
